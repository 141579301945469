<template>
    <div class="integral marginAuto">
        <div v-if="hideChildren">
            <div class="myIntegral">我的积分</div>
            <div class="myIntegralInfor flex alignCenter">
                <span>{{userInfo.point}}</span>
                <button class="cursorP" @click="hideChildren = false">积分规则</button>
            </div>
            <div class="integralInfor">
                <div class="integralInforBtn flex alignCenter spacearound cursorP" :class="integralCurr == 1 ? 'integralCurr' :''" @click="changeIntegral(1)">收入明细</div>
                <div class="integralInforBtn flex alignCenter spacearound cursorP" :class="integralCurr == 2 ? 'integralCurr' :''" @click="changeIntegral(2)">支出明细</div>
                <div class="clear"></div>
            </div>
            <table cellspacing="0">
                <tr style="height:55px;">
                    <th style="width:256px;">收入来源</th>
                    <th style="width:296px;">日期</th>
                    <th style="width:294px">积分变化</th>
                </tr>
                <tr v-for="(im,ix) in integralList[page-1]" :key="ix">
                    <td style="width:256px;height:82px;">{{im.desc}}</td>
                    <td style="position:relative;width:296px;height:82px;" class="">
                        {{im.ctime}}
                    </td>
                    <td style="width:294px;height:82px;">{{integralCurr == 1 ? '+' : '-'}}{{im.point}}</td>
                </tr>
            </table>
            <el-pagination
            style="margin-top:37px;"
            background
            layout="prev, pager, next"
            :total="dataCount"
            :page-size="10"
            @current-change="currentChange">
            </el-pagination>
        </div>
        <integral-rule v-else @hideChildren="hideChildren = true"></integral-rule>
    </div>
</template>
<script>
import { point_log } from '@/utils/Api/userList'
import integralRule from './integralRule'
export default {
    name:'integral',
    components:{integralRule},
    data(){
        return{
            integralCurr: 1,
            page: 1,
            integralList:[],
            hideChildren: true,
            dataCount:0
        }
    },
    created(){
        this.initial()
    },
    methods:{
        changeIntegral(val){
            this.integralCurr = val
            this.page = 1
            this.dataCount = 0
            this.integralList = []
            this.initial()
        },
        async initial(){
            let params = {
                type: this.integralCurr,
                page: this.page,
                limit: 10
            }
            let { res, count } = await point_log(params)
            for(let i = 0; i < Math.ceil(count / 10); i++){
                this.integralList.push([])
            }
            if(res){
                this.integralList[this.page-1] = res
                this.dataCount = count
            }
        },
        /* 改变页数 */
        currentChange(value){
            this.page = value
            this.initial()
            this.scrollTop()
        }
    },
    computed:{
        userInfo(){
            return this.$store.getters.personal
        }
    }
}
</script>
<style lang="scss" scoped>
table{
    text-align: center;
    width: 850px;
    margin-top: 8px;
    border: 1px solid #e8e8e8;
    tr{
        height: 82px;
    }
    th{
        background: #fafafa;
        font-size: 14px;
        height: 55px;
    }
    td{
        border: 1px solid #e8e8e8;
        overflow: hidden;
        font-size: 14px;
        color: #333;
    }
}
.integral{
    width: 96%;
    text-align: left;
    .myIntegral{
        margin-top: 20px;
        font-size: 15px;
        color: #312D2D;
        text-align: left;
    }
    .integralInfor{
        margin-top: 40px;
        .integralInforBtn{
            width: 134px;
            height: 51px;
            opacity: 1;
            background: #FAFAFA;
            font-size: 16px;
            color: #333;
            float: left;
        }
        .integralCurr{
            color: #fff!important;
            background: #47d7e3!important;
        }
    }
    .myIntegralInfor{
        span{
            color: #47D7E3;
            font-size: 38px;
            line-height: 81px;
        }
        button{
            width: 84px;
            height: 31px;
            opacity: 1;
            background: #47d7e3;
            border-radius: 16px;
            font-size: 15px;
            color: #fff;
            margin-left: 29px;
        }
    }
}
</style>