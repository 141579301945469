<template>
    <div class="integralRule">
        <p class="fontW flex spacebetween alignCenter" style="margin-top:38px;">
            <span style="font-size:16px;color:#000;">积分规则说明</span>
            <span class="cursorP cSkyBlue" @click="$emit('hideChildren')">返回</span>
        </p>
        <div style="margin-top:17px;" v-html="ruleContent"></div>
        <img src="@/assets/img/personal/getIntegral.png" style="width:870px;height:83px;margin-top:30px;" alt="">
        <div class=" eventList">
            <div class="list flex spacebetween alignCenter">
                <div class="listLeft flex alignCenter">
                    <img src="@/assets/img/personal/daySign.png" style="width:47px;height:47px;margin-right:27px;border-radius:3px;" alt="">
                    <div class="listLeftContent flex flexColumn spacebetween">
                        <span style="color:#191919;font-size:16px;">每日签到</span>
                        <span style="color:#A3A3A3;font-size:14px;">连续签到奖励更高</span>
                    </div>
                </div>
                <div class="leftRight flex alignCenter spacearound cursorP" @click="$store.dispatch('user/mysign')">
                    {{userInfo.is_sign == 0 ? '签到' : '已签到'}}
                </div>
            </div>
            <div class="list flex spacebetween alignCenter">
                <div class="listLeft flex alignCenter">
                    <img src="@/assets/img/personal/firend.png" style="width:47px;height:47px;margin-right:27px;border-radius:3px;" alt="">
                    <div class="listLeftContent flex flexColumn spacebetween">
                        <span style="color:#191919;font-size:16px;">邀请好友</span>
                        <span style="color:#A3A3A3;font-size:14px;">每成功邀请一位好友注册即可获得20积分</span>
                    </div>
                </div>
                <div class="leftRight flex alignCenter spacearound cursorP" @click="yaoqingchengyuan">
                    去邀请
                </div>
            </div>
            <div class="list flex spacebetween alignCenter">
                <div class="listLeft flex alignCenter">
                    <img src="@/assets/img/personal/teacher.png" style="width:47px;height:47px;margin-right:27px;border-radius:3px;" alt="">
                    <div class="listLeftContent flex flexColumn spacebetween">
                        <span style="color:#191919;font-size:16px;">推荐讲师</span>
                        <span style="color:#A3A3A3;font-size:14px;">每成功推荐一位讲师入住平台，即可获得200积分</span>
                    </div>
                </div>
                <div class="leftRight flex alignCenter spacearound cursorP" @click="goother({path:'/personal/recomteacher'})">
                    去推荐
                </div>
            </div>
            <div class="list flex spacebetween alignCenter">
                <div class="listLeft flex alignCenter">
                    <img src="@/assets/img/personal/course.png" style="width:47px;height:47px;margin-right:27px;border-radius:3px;" alt="">
                    <div class="listLeftContent flex flexColumn spacebetween">
                        <span style="color:#191919;font-size:16px;">观看课程</span>
                        <span style="color:#A3A3A3;font-size:14px;">观看课程80%可获得对应积分</span>
                    </div>
                </div>
                <div class="leftRight flex alignCenter spacearound cursorP" @click="goother({path:'/allcourse'})">
                    去观看
                </div>
            </div>
        </div>

        <el-dialog
            :visible.sync="dialogVisibleyaoqing"
            width="456px">
            <div class="inviteBox flex flexColumn alignCenter">
                <div class="inviteBoxTitle c33">
                    邀请好友
                </div>
                <img :src="inviteImg" alt="">
                <div class="downInviteBoxTitle flex alignCenter" @click="downImg">
                    <i class="el-icon-download" style="padding-right:8px;"></i>下载此二维码
                </div>
                <button class="closeInviteBox" @click="dialogVisibleyaoqing = false">关闭</button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
import { commonPrivacy, scerweima } from '@/utils/Api/commonList'
export default {
    name:'integralRule',
    data(){
        return{
            ruleContent:'',
            dialogVisibleyaoqing: false,
            inviteImg:''
        }
    },
    created(){
        this.initial()
    },
    methods:{
        /* 邀请好友 */
        yaoqingchengyuan(){
            scerweima({
                url: `/pages/entry/home`,
                invite_code: this.userInfo.code,
                organ_id: this.userInfo.id
            }).then(res =>{
                this.inviteImg = res
                console.log(res)
            })
            this.dialogVisibleyaoqing=true
        },
        async initial(){
            let rule = await commonPrivacy({type: 5})
            console.log(rule)
            if(rule){
                this.ruleContent = rule.content
            }
        },
         downImg(){
            window.open(this.inviteImg)
        },
    },
    computed:{
        userInfo(){
            return this.$store.getters.personal
        }
    }
}
</script>
<style lang="scss" scoped>
.inviteBox{
    width: 456px;
    height: 350px;
    overflow: hidden;
    margin-left: -20px;
    .closeInviteBox{
        width: 79px;
        height: 33px;
        opacity: 1;
        background: #47d7e3;
        border-radius: 2px;
        color: #FFFFFF;
        font-size: 15px;
        margin-top: 15px;
        cursor: pointer;
        line-height: 13px;
    }
    .downInviteBoxTitle{
        text-align: center;
        font-size: 13px;
        color: #47D7E3;
        width: 100px;
        margin: auto;
        margin-top: 11px;
        cursor: pointer;
    }
    .inviteBoxTitle{
        font-size: 20px;
        margin-top: 36px;
    }
    img{
        margin: auto;
        margin-top: 25px;
        width: 158px;
        height: 158px;
    }
}
.integralRule{
    .eventList{
        width: 870px;
        height: 300px;
        margin-top: 40px;
        .list{
            height: 100px;
            border-bottom: 1px solid #d5d5d5;
            .listLeft{
                height: 47px;
            }
            .listLeftContent{
                height: 100%;
            }
            .leftRight{
                width: 71px;
                height: 30px;
                background: #1dcad3;
                border-radius: 20px;
                font-size: 14px;
                color: #fff;
            }
        }
    }
}
</style>